<template>
  <div id="app">
    <span
      :style="`background-image: url(img/${$company}.jpg);`"
      class="bg"
    />
    <v-app>
      <pages-core-app-bar />
      <v-container
        id="login"
        class="fill-height"
        tag="section"
      >
        <v-row justify="center">
          <v-slide-y-transition appear>
            <base-material-card
              color="success"
              light
              max-width="100%"
              width="400"
              class="px-5 py-3"
            >
              <template #heading>
                <div class="text-center">
                  <v-icon
                    large
                    v-text="'mdi-login-variant'"
                  />
                  <h1 class="display-2 mb-2">
                    {{ $t('pages.login.Login_message') }}
                  </h1>
                  <h1 class="display-2 font-weight-bold mb-2">
                    01Cell
                  </h1>
                </div>
              </template>

              <component
                :is="currentAuthentification"
              />
            </base-material-card>
          </v-slide-y-transition>
        </v-row>
      </v-container>
      <pages-core-footer />
    </v-app>
  </div>
</template>

<script>
  // Component
  import LoginMsal from '@/components/Login/LoginMsal'
  import LoginOidc from '@/components/Login/LoginOidc'

  export default {
    name: 'PagesLogin',

    components: {
      PagesCoreAppBar: () => import('@/views/pages/components/core/AppBar'),
      PagesCoreFooter: () => import('@/views/pages/components/core/Footer'),
      LoginMsal,
      LoginOidc,
    },

    computed: {
      currentAuthentification () {
        if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') return LoginMsal
        if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') return LoginOidc
        return LoginMsal
      },
    },
  }
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    filter: brightness(40%);
    /* background-color: red; */
    transform: scale(1.1);
  }
</style>
